import {Layout} from "../Layout";
import React from "react";
import {Language} from "../../model/LanguageBundle";
import {LayoutContentArea} from "../LayoutContentArea";
import * as styles from "./Impressum.module.scss";
import {Section} from "../Section";
import AddressImage from "../../assets/contact.svg"

export default ({language, href}: { language: Language, href: string }) => {
  return (
    <Layout
      title="Impressum"
      description="Impressum von bastian-nolte.name."
      language={language}
      href={href}
      helmetKids={<meta name="robots" content="noindex"/>}
    >
      <LayoutContentArea>
        <header id="impressum-und-datenschutz" className={styles.header}>
          <h1>Impressum & Datenschutz</h1>
          <h2>Informationen zum Betreiber und zum Datenschutz</h2>
        </header>
        <Section id="impressum" title="Impressum" language={language}>
          <div className={styles.content}>
            <address>
              <h2>Bastian Nolte</h2>
              <img className={styles.addressImage} src={AddressImage} alt="Adressinformation"/>
            </address>
            <h2>Dieses Impressum gilt zusätzlich für folgende Profil-Webseiten:</h2>
            <ul className={styles.profileList}>
              <li><a href="https://www.xing.com/profile/Bastian_Nolte" rel="noopener" target="_blank">Xing:
                https://www.xing.com/profile/Bastian_Nolte</a>
              </li>
              <li><a href="https://www.gulp.ch/gulp2/home/profil/bastian-nolte" rel="noopener" target="_blank">Gulp:
                https://www.gulp.ch/gulp2/home/profil/bastian-nolte</a></li>
              <li><a href="https://www.linkedin.com/in/bastian-nolte" rel="noopener" target="_blank">Linkedin:
                https://www.linkedin.com/in/bastian-nolte</a></li>
            </ul>
            <h2>Haftung für Links</h2>
            <p>Die aufgeführten externen Links führen zu Inhalten fremder Anbieter. Für diese Inhalte ist allein der jeweilige Anbieter
              verantwortlich. Bei bekannt werden von Rechtsverletzungen werden diese Links umgehend entfernt.
            </p>
          </div>
        </Section>

        <Section id="datenschutz" title="Datenschutzerklärung" language={language}>
          <div className={styles.datenschutz}>
            <p>Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von
              personenbezogenen Daten (nachfolgend kurz „Daten“) innerhalb unseres Onlineangebotes und der mit ihm
              verbundenen Webseiten, Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social
              Media Profile auf. (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick auf die
              verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die
              Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).
            </p>
            <p className={styles.lead}>Verantwortlicher</p>

            <p>
              Bastian Nolte<br/>
              <img className={styles.addressImage} src={AddressImage} alt="Adressinformation"/>
            </p>

            <p className={styles.lead}>Arten der verarbeiteten Daten:</p>
            <ul>
              <li>Bestandsdaten (z.B., Namen, Adressen).</li>
              <li>Kontaktdaten (z.B., E-Mail, Telefonnummern).</li>
              <li>Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).</li>
              <li>Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).</li>
              <li>Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).</li>
            </ul>

            <p className={styles.lead}>Kategorien betroffener Personen</p>

            <p>Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen Personen
              zusammenfassend auch als „Nutzer“).
            </p>

            <p className={styles.lead}>Zweck der Verarbeitung</p>
            <ul>
              <li>Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte.</li>
              <li>Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.</li>
              <li>Sicherheitsmaßnahmen.</li>
              <li>Reichweitenmessung/Marketing</li>
            </ul>

            <p className={styles.lead}>Verwendete Begrifflichkeiten</p>
            <p>
              „Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder
              identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar
              wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu
              einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B.
              Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der
              physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen
              Identität dieser natürlichen Person sind.
            </p>
            <p>
              „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder
              jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und
              umfasst praktisch jeden Umgang mit Daten.
            </p>
            <p>
              Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere
              Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
              personenbezogenen Daten entscheidet, bezeichnet.
            </p>

            <p className={styles.lead}>Maßgebliche Rechtsgrundlagen</p>
            <p>Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen
              mit. Sofern die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die
              Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die
              Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher
              Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die Rechtsgrundlage für
              die Verarbeitung zur Erfüllung unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO,
              und die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer berechtigten Interessen ist Art. 6
              Abs. 1 lit. f DSGVO. Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer
              anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art.
              6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
            </p>

            <p className={styles.lead}>Zusammenarbeit mit Auftragsverarbeitern und Dritten</p>
            <p>Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und Unternehmen
              (Auftragsverarbeitern oder Dritten) offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff
              auf die Daten gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine
              Übermittlung der Daten an Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b DSGVO zur
              Vertragserfüllung erforderlich ist), Sie eingewilligt haben, eine rechtliche Verpflichtung dies
              vorsieht oder auf Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von Beauftragten,
              Webhostern, etc.).
            </p>
            <p>Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines sog.
              „Auftragsverarbeitungsvertrages“ beauftragen, geschieht dies auf Grundlage des Art. 28 DSGVO.
            </p>
            <p className={styles.lead}>Übermittlungen in Drittländer</p>
            <p>Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU) oder des
              Europäischen Wirtschaftsraums (EWR)) verarbeiten oder dies im Rahmen der Inanspruchnahme von
              Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an Dritte geschieht, erfolgt dies
              nur, wenn es zur Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung,
              aufgrund einer rechtlichen Verpflichtung oder auf Grundlage unserer berechtigten Interessen
              geschieht. Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten oder lassen wir
              die Daten in einem Drittland nur beim Vorliegen der besonderen Voraussetzungen der Art. 44 ff. DSGVO
              verarbeiten. D.h. die Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien, wie der
              offiziell anerkannten Feststellung eines der EU entsprechenden Datenschutzniveaus (z.B. für die USA
              durch das „Privacy Shield“) oder Beachtung offiziell anerkannter spezieller vertraglicher
              Verpflichtungen (so genannte „Standardvertragsklauseln“).
            </p>

            <p className={styles.lead}>Rechte der betroffenen Personen</p>
            <p>Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden
              und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend
              Art. 15 DSGVO.
            </p>
            <p>Sie haben entsprechend. Art. 16 DSGVO das Recht, die Vervollständigung der Sie betreffenden Daten
              oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
            </p>
            <p>Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass betreffende Daten unverzüglich
              gelöscht werden, bzw. alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung
              der Daten zu verlangen.
            </p>
            <p>Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben
              nach Maßgabe des Art. 20 DSGVO zu erhalten und deren Übermittlung an andere Verantwortliche zu
              fordern.
            </p>
            <p>Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde
              einzureichen.
            </p>

            <p className={styles.lead}>Widerrufsrecht</p>

            <p>Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu widerrufen</p>

            <p className={styles.lead}>Widerspruchsrecht</p>
            <p>Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des Art. 21 DSGVO
              jederzeit widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der
              Direktwerbung erfolgen.
            </p>

            <p className={styles.lead}>Cookies und Widerspruchsrecht bei Direktwerbung</p>

            <p>Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer gespeichert werden.
              Innerhalb der Cookies können unterschiedliche Angaben gespeichert werden. Ein Cookie dient primär
              dazu, die Angaben zu einem Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während oder
              auch nach seinem Besuch innerhalb eines Onlineangebotes zu speichern. Als temporäre Cookies, bzw.
              „Session-Cookies“ oder „transiente Cookies“, werden Cookies bezeichnet, die gelöscht werden, nachdem
              ein Nutzer ein Onlineangebot verlässt und seinen Browser schließt. In einem solchen Cookie kann z.B.
              der Inhalt eines Warenkorbs in einem Onlineshop oder ein Login-Staus gespeichert werden. Als
              „permanent“ oder „persistent“ werden Cookies bezeichnet, die auch nach dem Schließen des Browsers
              gespeichert bleiben. So kann z.B. der Login-Status gespeichert werden, wenn die Nutzer diese nach
              mehreren Tagen aufsuchen. Ebenso können in einem solchen Cookie die Interessen der Nutzer
              gespeichert werden, die für Reichweitenmessung oder Marketingzwecke verwendet werden. Als
              „Third-Party-Cookie“ werden Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen,
              der das Onlineangebot betreibt, angeboten werden (andernfalls, wenn es nur dessen Cookies sind
              spricht man von „First-Party Cookies“).
            </p>

            <p>Wir können temporäre und permanente Cookies einsetzen und klären hierüber im Rahmen unserer
              Datenschutzerklärung auf.
            </p>
            <p>Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie
              gebeten die entsprechende Option in den Systemeinstellungen ihres Browsers zu deaktivieren.
              Gespeicherte Cookies können in den Systemeinstellungen des Browsers gelöscht werden. Der Ausschluss
              von Cookies kann zu Funktionseinschränkungen dieses Onlineangebotes führen.
            </p>
            <p>Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies
              kann bei einer Vielzahl der Dienste, vor allem im Fall des Trackings, über die US-amerikanische
              Seite https://www.aboutads.info/choices/ oder die EU-Seite https://www.youronlinechoices.com/ erklärt
              werden. Des Weiteren kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen
              des Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen
              dieses Onlineangebotes genutzt werden können.
            </p>

            <p className={styles.lead}>Löschung von Daten</p>

            <p>Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DSGVO gelöscht oder in ihrer
              Verarbeitung eingeschränkt. Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich
              angegeben, werden die bei uns gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung
              nicht mehr erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten
              entgegenstehen. Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige
              Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und
              nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
              steuerrechtlichen Gründen aufbewahrt werden müssen.
            </p>
            <p>Nach gesetzlichen Vorgaben in Deutschland erfolgt die Aufbewahrung insbesondere für 6 Jahre gemäß §
              257 Abs. 1 HGB (Handelsbücher, Inventare, Eröffnungsbilanzen, Jahresabschlüsse, Handelsbriefe,
              Buchungsbelege, etc.) sowie für 10 Jahre gemäß § 147 Abs. 1 AO (Bücher, Aufzeichnungen,
              Lageberichte, Buchungsbelege, Handels- und Geschäftsbriefe, Für Besteuerung relevante Unterlagen,
              etc.).
            </p>
            <p>Nach gesetzlichen Vorgaben in Österreich erfolgt die Aufbewahrung insbesondere für 7 J gemäß § 132
              Abs. 1 BAO (Buchhaltungsunterlagen, Belege/Rechnungen, Konten, Belege, Geschäftspapiere, Aufstellung
              der Einnahmen und Ausgaben, etc.), für 22 Jahre im Zusammenhang mit Grundstücken und für 10 Jahre
              bei Unterlagen im Zusammenhang mit elektronisch erbrachten Leistungen, Telekommunikations-,
              Rundfunk- und Fernsehleistungen, die an Nichtunternehmer in EU-Mitgliedstaaten erbracht werden und
              für die der Mini-One-Stop-Shop (MOSS) in Anspruch genommen wird.
            </p>

            <p className={styles.lead}>Geschäftsbezogene Verarbeitung</p>
            <ul>Zusätzlich verarbeiten wir
              <li>Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit, Kundenkategorie).</li>
              <li>Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie)
                von unseren Kunden, Interessenten und Geschäftspartner zwecks Erbringung vertraglicher
                Leistungen,
                Service und Kundenpflege, Marketing, Werbung und Marktforschung.
              </li>
            </ul>

            <p className={styles.lead}>Hosting</p>
            <p>Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden
              Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
              Datenbankdienste, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zwecke des
              Betriebs dieses Onlineangebotes einsetzen.
            </p>
            <p>Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten,
              Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern
              dieses Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer effizienten und
              sicheren Zurverfügungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28
              DSGVO (Abschluss Auftragsverarbeitungsvertrag).
            </p>
            <p className={styles.lead}>Erhebung von Zugriffsdaten und Logfiles</p>
            <p>Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer berechtigten Interessen im Sinne des
              Art. 6 Abs. 1 lit. f. DSGVO Daten über jeden Zugriff auf den Server, auf dem sich dieser Dienst
              befindet (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der abgerufenen Webseite,
              Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf,
              Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite),
              IP-Adresse und der anfragende Provider.
            </p>
            <p>Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung von Missbrauchs- oder
              Betrugshandlungen) für die Dauer von maximal 7 Tagen gespeichert und danach gelöscht. Daten, deren
              weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des
              jeweiligen Vorfalls von der Löschung ausgenommen.
            </p>

            <p className={styles.lead}>Erbringung vertraglicher Leistungen</p>
            <p>Wir verarbeiten Bestandsdaten (z.B., Namen und Adressen sowie Kontaktdaten von Nutzern),
              Vertragsdaten (z.B., in Anspruch genommene Leistungen, Namen von Kontaktpersonen,
              Zahlungsinformationen) zwecks Erfüllung unserer vertraglichen Verpflichtungen und Serviceleistungen
              gem. Art. 6 Abs. 1 lit b. DSGVO. Die in Onlineformularen als verpflichtend gekennzeichneten
              Eingaben, sind für den Vertragsschluss erforderlich.
            </p>

            <p>Im Rahmen der Inanspruchnahme unserer Onlinedienste, speichern wir die IP-Adresse und den Zeitpunkt
              der jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf Grundlage unserer berechtigten
              Interessen, als auch der Nutzer an Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine
              Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, außer sie ist zur Verfolgung unserer
              Ansprüche erforderlich oder es besteht hierzu eine gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit.
              c DSGVO.
            </p>

            <p>Wir verarbeiten Nutzungsdaten (z.B., die besuchten Webseiten unseres Onlineangebotes, Interesse an
              unseren Produkten) und Inhaltsdaten (z.B., Eingaben im Kontaktformular oder Nutzerprofil) für
              Werbezwecke in einem Nutzerprofil, um den Nutzer z.B. Produkthinweise ausgehend von ihren bisher in
              Anspruch genommenen Leistungen einzublenden.
            </p>

            <p>Die Löschung der Daten erfolgt nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer
              Pflichten, die Erforderlichkeit der Aufbewahrung der Daten wird alle drei Jahre überprüft; im Fall
              der gesetzlichen Archivierungspflichten erfolgt die Löschung nach deren Ablauf. Angaben im etwaigen
              Kundenkonto verbleiben bis zu dessen Löschung.
            </p>

            <p className={styles.lead}>Administration, Finanzbuchhaltung, Büroorganisation, Kontaktverwaltung</p>
            <p>Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie Organisation unseres Betriebs,
              Finanzbuchhaltung und Befolgung der gesetzlichen Pflichten, wie z.B. der Archivierung. Herbei
              verarbeiten wir dieselben Daten, die wir im Rahmen der Erbringung unserer vertraglichen Leistungen
              verarbeiten. Die Verarbeitungsgrundlagen sind Art. 6 Abs. 1 lit. c. DSGVO, Art. 6 Abs. 1 lit. f.,
              Art. 28 DSGVO. Von der Verarbeitung sind Betroffene: Kunden, Interessenten, Geschäftspartner und
              Websitebesucher betroffen. Der Zweck und unser Interesse an der Verarbeitung liegt in der
              Administration, Finanzbuchhaltung, Büroorganisation, Archivierung von Daten, also Aufgaben die der
              Aufrechterhaltung unserer Geschäftstätigkeiten, Wahrnehmung unserer Aufgaben und Erbringung unserer
              Leistungen dienen. Die Löschung der Daten im Hinblick auf vertragliche Leistungen und die
              vertragliche Kommunikation entspricht den, bei diesen Verarbeitungstätigkeiten genannten Angaben.
            </p>
            <p>Wir offenbaren oder übermitteln hierbei Daten an die Finanzverwaltung, Berater, wie z.B.,
              Steuerberater oder Wirtschaftsprüfer sowie weitere Gebührenstellen und Zahlungsdienstleister.
            </p>
            <p>Ferner speichern wir auf Grundlage unserer betriebswirtschaftlichen Interessen Angaben zu
              Lieferanten, Veranstaltern und sonstigen Geschäftspartnern, z.B. zwecks späterer Kontaktaufnahme.
              Diese mehrheitlich unternehmensbezogenen Daten, speichern wir grundsätzlich dauerhaft.
            </p>

            <p className={styles.lead}>Betriebswirtschaftliche Analysen und Marktforschung</p>
            <p>Um unser Geschäft wirtschaftlich betreiben, Markttendenzen, Kunden- und Nutzerwünsche erkennen zu
              können, analysieren wir die uns vorliegenden Daten zu Geschäftsvorgängen, Verträgen, Anfragen, etc.
              Wir verarbeiten dabei Bestandsdaten, Kommunikationsdaten, Vertragsdaten, Zahlungsdaten,
              Nutzungsdaten, Metadaten auf Grundlage des Art. 6 Abs. 1 lit. f. DSGVO, wobei zu den betroffenen
              Personen Kunden, Interessenten, Geschäftspartner, Besucher und Nutzer des Onlineangebotes gehören.
            </p>
            <p>Die Analysen erfolgen zum Zweck betriebswirtschaftliche Auswertungen, des Marketings und der
              Marktforschung. Dabei können wir die Profile der registrierten Nutzer mit Angaben z.B. zu deren
              Kaufvorgängen berücksichtigen. Die Analysen dienen uns zur Steigerung der Nutzerfreundlichkeit, der
              Optimierung unseres Angebotes und der Betriebswirtschaftlichkeit. Die Analysen dienen alleine uns
              und werden nicht extern offenbart, sofern es sich nicht um anonyme Analysen mit zusammengefassten
              Werten handelt.
            </p>
            <p>Sofern diese Analysen oder Profile personenbezogen sind, werden sie mit Kündigung der Nutzer
              gelöscht oder anonymisiert, sonst nach zwei Jahren ab Vertragsschluss. Im Übrigen werden die
              gesamtbetriebswirtschaftlichen Analysen und allgemeine Tendenzbestimmungen nach Möglichkeit anonym
              erstellt.
            </p>

            <p className={styles.lead}>Amazon-Partnerprogramm</p>
            <p>Wir sind auf Grundlage unserer berechtigten Interessen (d.h. Interesse am wirtschaftlichem Betrieb
              unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Teilnehmer des Partnerprogramms
              von Amazon EU, das zur Bereitstellung eines Mediums für Websites konzipiert wurde, mittels dessen
              durch die Platzierung von Werbeanzeigen und Links zu Amazon.de Werbekostenerstattung verdient werden
              kann (sog. Affiliate-System). Amazon setzt Cookies ein, um die Herkunft der Bestellungen
              nachvollziehen zu können. Unter anderem kann Amazon erkennen, dass Sie den Partnerlink auf dieser
              Website geklickt und anschließend ein Produkt bei Amazon erworben haben.
            </p>
            <p>Weitere Informationen zur Datennutzung durch Amazon und Widerspruchsmöglichkeiten erhalten Sie in
              der Datenschutzerklärung des Unternehmens:
              https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401.
            </p>

            <p className={styles.lead}>Kontaktaufnahme</p>
            <p>Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via sozialer Medien)
              werden die Angaben des Nutzers zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6
              Abs. 1 lit. b) DSGVO verarbeitet. Die Angaben der Nutzer können in einem
              Customer-Relationship-Management System ("CRM System") oder vergleichbarer Anfragenorganisation
              gespeichert werden.
            </p>
            <p>Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir überprüfen die
              Erforderlichkeit alle zwei Jahre; Ferner gelten die gesetzlichen Archivierungspflichten.
            </p>

            <p className={styles.lead}>Google Analytics</p>
            <p>Wir setzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung
              und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO)
              Google Analytics, einen Webanalysedienst der Google LLC („Google“) ein. Google verwendet Cookies.
              Die durch das Cookie erzeugten Informationen über Benutzung des Onlineangebotes durch die Nutzer
              werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
            </p>
            <p>Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das
              europäische Datenschutzrecht einzuhalten
              (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active).
            </p>

            <p>Google wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres Onlineangebotes
              durch die Nutzer auszuwerten, um Reports über die Aktivitäten innerhalb dieses Onlineangebotes
              zusammenzustellen und um weitere, mit der Nutzung dieses Onlineangebotes und der Internetnutzung
              verbundene Dienstleistungen, uns gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten
              pseudonyme Nutzungsprofile der Nutzer erstellt werden.
            </p>
            <p>Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Das bedeutet, die IP-Adresse
              der Nutzer wird von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
              Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen
              wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.
            </p>
            <p>Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit anderen Daten von Google
              zusammengeführt. Die Nutzer können die Speicherung der Cookies durch eine entsprechende Einstellung
              ihrer Browser-Software verhindern; die Nutzer können darüber hinaus die Erfassung der durch das
              Cookie erzeugten und auf ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die
              Verarbeitung dieser Daten durch Google verhindern, indem sie das unter folgendem Link verfügbare
              Browser-Plugin herunterladen und installieren: https://tools.google.com/dlpage/gaoptout?hl=de.
            </p>
            <p>Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten
              erfahren Sie auf den Webseiten von Google: https://www.google.com/intl/de/policies/privacy/partners
              („Datennutzung durch Google bei Ihrer Nutzung von Websites oder Apps unserer Partner“),
              https://www.google.com/policies/technologies/ads („Datennutzung zu Werbezwecken“),
              https://www.google.de/settings/ads („Informationen verwalten, die Google verwendet, um Ihnen Werbung
              einzublenden“).
            </p>

            <p className={styles.lead}>Onlinepräsenzen in sozialen Medien</p>
            <p>Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und Plattformen, um mit den dort
              aktiven Kunden, Interessenten und Nutzern kommunizieren und sie dort über unsere Leistungen
              informieren zu können. Beim Aufruf der jeweiligen Netzwerke und Plattformen gelten die
              Geschäftsbedingungen und die Datenverarbeitungsrichtlinien deren jeweiligen Betreiber.
            </p>
            <p>Soweit nicht anders im Rahmen unserer Datenschutzerklärung angegeben, verarbeiten wir die Daten der
              Nutzer sofern diese mit uns innerhalb der sozialen Netzwerke und Plattformen kommunizieren, z.B.
              Beiträge auf unseren Onlinepräsenzen verfassen oder uns Nachrichten zusenden.
            </p>
            <p className={styles.lead}>Einbindung von Diensten und Inhalten Dritter</p>
            <p>Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer berechtigten Interessen (d.h.
              Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne
              des Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von Drittanbietern ein, um deren
              Inhalte und Services, wie z.B. Videos oder Schriftarten einzubinden (nachfolgend einheitlich
              bezeichnet als “Inhalte”).
            </p>
            <p>Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die IP-Adresse der Nutzer
              wahrnehmen, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die
              IP-Adresse ist damit für die Darstellung dieser Inhalte erforderlich. Wir bemühen uns nur solche
              Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte
              verwenden. Drittanbieter können ferner so genannte Pixel-Tags (unsichtbare Grafiken, auch als "Web
              Beacons" bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die "Pixel-Tags" können
              Informationen, wie der Besucherverkehr auf den Seiten dieser Website ausgewertet werden. Die
              pseudonymen Informationen können ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und
              unter anderem technische Informationen zum Browser und Betriebssystem, verweisende Webseiten,
              Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten, als auch mit
              solchen Informationen aus anderen Quellen verbunden werden.
            </p>

            <p className={styles.lead}>Xing</p>
            <p>Innerhalb unseres Onlineangebotes können Funktionen und Inhalte des Dienstes Xing eingebunden,
              angeboten durch die XING AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland. Hierzu können z.B.
              Inhalte wie Bilder, Videos oder Texte und Schaltflächen gehören, mit denen Nutzer Ihr Gefallen
              betreffend die Inhalte kundtun, den Verfassern der Inhalte oder unsere Beiträge abonnieren können.
              Sofern die Nutzer Mitglieder der Plattform Xing sind, kann Xing den Aufruf der o.g. Inhalte und
              Funktionen den dortigen Profilen der Nutzer zuordnen. Datenschutzerklärung von Xing:
              https://www.xing.com/app/share?op=data_protection..
            </p>

            <p className={styles.lead}>LinkedIn</p>
            <p>Innerhalb unseres Onlineangebotes können Funktionen und Inhalte des Dienstes LinkedIn eingebunden,
              angeboten durch die LinkedIn AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland. Hierzu können z.B.
              Inhalte wie Bilder, Videos oder Texte und Schaltflächen gehören, mit denen Nutzer Ihr Gefallen
              betreffend die Inhalte kundtun, den Verfassern der Inhalte oder unsere Beiträge abonnieren können.
              Sofern die Nutzer Mitglieder der Plattform LinkedIn sind, kann LinkedIn den Aufruf der o.g. Inhalte
              und Funktionen den dortigen Profilen der Nutzer zuordnen. Datenschutzerklärung von LinkedIn:
              https://www.linkedin.com/legal/privacy-policy.. LinkedIn ist unter dem Privacy-Shield-Abkommen
              zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten
              (https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active).
              Datenschutzerklärung: https://twitter.com/de/privacy, Opt-Out:
              https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out.
            </p>
            <p>Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas Schwenke</p>
          </div>
        </Section>
      </LayoutContentArea>
    </Layout>
  )
}
