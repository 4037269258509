import React, {CSSProperties, FunctionComponent} from "react";
import * as styles from './Section.module.scss'
import {t} from "../i18n";
import {Language} from "../model/LanguageBundle";

interface SectionProps {
  customstyles?: CSSProperties
  customClasses?: string
  id: string
  title: string
  subtitle?: string
  noIndex?: boolean
  avoidPageBreak?: boolean
  navigation?: boolean
  language: Language
}

export const Section: FunctionComponent<SectionProps> = ({customstyles, customClasses, id, title, subtitle, noIndex, avoidPageBreak, navigation, language, children}) => {
  const navigationCssClass = navigation ? 'section-anchor' : ''
  const avoidPageBreakCssClass = avoidPageBreak ? styles.avoidPageBreak : ''

  // noinspection HtmlUnknownAnchorTarget
  return (
    <section
      data-nosnippet={noIndex}
      id={id}
      className={`${styles.section} ${navigationCssClass} ${avoidPageBreakCssClass} ${customClasses}`}
      style={{...customstyles}}>
      <div className={styles.header}>
        <h1>{title}</h1>
        {subtitle && <h2>{subtitle}</h2>}
      </div>
      <div className={styles.content}>
        {children}
        <div className={styles.navigateToTop}><a href="#top" style={{textDecoration: "none"}}>⇪ {t({ de: "an den Anfang", en: "back to top"}, language)}</a></div>
      </div>
    </section>
  )
}
