import React from "react";
import {siteMetaData} from "../siteMetaData";
import {PageProps} from "gatsby";
import Impressum from "../components/impressum/Impressum";

export default (props: PageProps) => {
  const language = "de"
  return (
    <Impressum
      language={language}
      href={`${siteMetaData.getUrlForPath(props.location.pathname)}${props.location.hash}`}/>
  )
}
